<template>
  <b-container class='mt-4'>
    <b-spinner v-if='loading'></b-spinner>
    <div v-else>
      <b-row>
        <b-col class='d-flex align-items-center'>
          <BackButton></BackButton>
          <h5 class='ml-2'>Generic Importer Settings for client
            <b-link v-if='client' :to='`/onboarding/client/edit/${client.id}`'>{{ client.display_name }}</b-link>
          </h5>
        </b-col>
        <b-col class='d-flex justify-content-end'>
          <b-button class='ml-3' @click='redirectToEditConfiguration' type='button' :disabled='saving' id='btn_submit'
                    variant='primary'>
            <b-spinner label='Loading...' variant='light' small v-if='saving'></b-spinner>
            <span v-else>Edit configuration</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class='mt-4'>
        <b-col>
          <b-card class='mb-4'>
            <template #header>
              <h6 class='mb-0'><b>{{ fileTypeDetails.name }}</b> File configuration</h6>
            </template>

            <b-alert v-if="uniqueIdConfigs" show>
              The following columns will be used to generate the Unique ID: {{ uniqueIdConfigs.join(', ') }}
            </b-alert>
            <b-alert v-else variant="warning" show>
              No unique key configured for this client. The ingestion will not work if the config key is not configured.
            </b-alert>

            <FileConfiguration
              :fileTypeDetails="fileTypeDetails"
              :filepaths="filepaths"
              :eligibilityProviders="providers.filter(p => p.type === 'eligibility').map(p => {
                return {
                  'id': p.id,
                  'filepath': p.filepath
                }
              })"
              :readOnly="true"
              :uniqueIdConfigs="uniqueIdConfigs"
              :configuration="configuration"/>

            <hr>
            <b-row class="mt-4">
              <b-col cols="12" class="mb-2">
                <h5>Custom script</h5>
                <small>Custom Python script that is going to run for each row during the ingestion</small>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="providerCode">
                <code-highlight language="python">{{ providerCode }}</code-highlight>
              </b-col>
              <b-col v-else>
                <b-alert variant="info" show>
                  No custom script configured for this filepath.
                </b-alert>
              </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
              <b-col cols="12" class="mb-2">
                <h5>Metadata</h5>
                <small>Information regarding the last ingestion made for this configuration</small>
              </b-col>
              <b-col v-if="metadata">
                <div class="metadata-container">
                  <div class="metadata-row">
                    <div class="metadata-label">Ingestion ID:</div>
                    <div class="metadata-value">{{ metadata.ingestion_id }}</div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">File Name:</div>
                    <div class="metadata-value">{{ metadata.file_name }}</div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">File Size:</div>
                    <div class="metadata-value">{{ metadata.file_size }}</div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Valid Rows:</div>
                    <div class="metadata-value">{{ metadata.valid_rows }}</div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Invalid Rows:</div>
                    <div class="metadata-value">{{ metadata.invalid_rows }}</div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Rows with Warnings:</div>
                    <div class="metadata-value">{{ metadata.rows_with_warnings }}</div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Unique ID Combination:</div>
                    <div class="metadata-value">{{ metadata.unique_id_combination }}</div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Success:</div>
                    <div class="metadata-value"
                         :class="{ 'text-success': metadata.success, 'text-danger': !metadata.success }">
                      {{ metadata.success ? 'Yes' : 'No' }}
                    </div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Errors:</div>
                    <div class="metadata-value">
                      <div class="table-container" v-if="metadata.errors && metadata.errors.length">
                        <b-table striped hover :items="metadata.errors">
                          <template #cell(line)="data">
                            {{ data.value }}
                          </template>
                          <template #cell(message)="data">
                            {{ data.value }}
                          </template>
                        </b-table>
                      </div>
                      <span v-else>No errors</span>
                    </div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Warnings:</div>
                    <div class="metadata-value">
                      <div class="table-container" v-if="metadata.warnings && metadata.warnings.length">
                        <b-table striped hover :items="metadata.warnings">
                          <template #cell(line)="data">
                            {{ data.value }}
                          </template>
                          <template #cell(message)="data">
                            {{ data.value }}
                          </template>
                        </b-table>
                      </div>
                      <span v-else>No warnings</span>
                    </div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Details:</div>
                    <div class="metadata-value">
                      <textarea
                        v-if="metadata.details"
                        class="form-control"
                        rows="5"
                        :value="metadata.details"
                        readonly
                        style="overflow-y: scroll;"/>
                      <span v-else>No details</span>
                    </div>
                  </div>
                  <div class="metadata-row">
                    <div class="metadata-label">Ingested At:</div>
                    <div class="metadata-value">{{ metadata.created_at }}</div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import BackButton from '@/components/BackButton.vue';
import FileConfiguration from '@/views/Onboarding/GenericImporter/FileConfiguration.vue';
import CodeHighlight from 'vue-code-highlight/src/CodeHighlight.vue';
import 'vue-code-highlight/themes/prism-tomorrow.css';


export default {
  components: {
    FileConfiguration,
    BackButton,
    CodeHighlight,
  },
  computed: {
    providerCode() {
      const code = this.providers.filter(p => p.id === Number(this.$route.params.providerId))[0]?.custom_script;
      if (!code) {
        return null;
      }

      return Buffer.from(code, 'base64').toString('utf8');
    },
  },
  data() {
    return {
      saving: false,
      loading: true,
      client: null,
      uniqueIdConfigs: [],
      fileTypeDetails: {},
      providers: [],
      configuredFilepaths: [],
      filepaths: [],
      configuration: {
        delimiter: null,
        external_unique_id_column_number: null,
        provider_id: null,
        normalizers: {
          relationship: [],
          gender: [],
          date: [],
        },
        genericEligibilityMapping: [],
      },
      isFormValid: null,
      metadata: null,
    };
  },
  async beforeMount() {
    await this.loadPage();
  },
  methods: {
    async loadPage() {
      this.loading = true;
      const clients = await this.$store.dispatch('Core/fetchClients');
      this.client = clients.find(c => Number(c.id) === Number(this.$route.params.clientId));
      await this.getProviders();
      await Promise.all([
        this.getClientConfigs(this.$route.params.clientId),
        this.getFileTypeDetails(this.$route.params.fileTypeId),
      ]);

      this.providers.forEach(p => {
        this.filepaths.push({
          label: p.filepath,
          id: p.id,
        });
      });

      await this.getConfiguration();
      this.loading = false;
    },
    async getClientConfigs(clientId) {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getClientImporters', { clientId });
      this.uniqueIdConfigs = res?.data?.unique_id_fields || [];
    },
    async getFileTypeDetails(id) {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getGenericImporterConfigs', id);
      this.fileTypeDetails = res?.data || {};
    },
    async getConfiguration() {
      const { data: details } = await this.$store.dispatch('Sftp/GenericImporter/getGenericImportClientConfiguration', {
        providerId: this.$route.params.providerId,
        importerId: this.$route.params.fileTypeId,
        onboardingClientId: this.$route.params.clientId,
      });

      this.configuration.delimiter = details.delimiter;
      this.configuration.external_unique_id_column_number = details.external_unique_id_column_number;
      this.configuration.provider_id = Number(this.$route.params.providerId);
      this.configuration.mapping = details.mapping || null;
      this.configuration.normalizers.relationship = details?.normalizers?.relationship;
      this.configuration.normalizers.gender = details?.normalizers?.gender;
      this.configuration.normalizers.date = details?.normalizers?.date;
      this.configuration.genericEligibilityMapping = details?.genericEligibilityMapping || [];
      this.metadata = details?.last_ingestion_record;
      this.configuration.quarantine_config = {
        enabled: Boolean(details.quarantine_enabled),
        mapping: details.quarantine_config || [],
      };
    },
    async getProviders() {
      const res = await this.$store.dispatch('Sftp/Provider/getProvidersList');
      this.providers = res?.data || [];
    },
    redirectToEditConfiguration() {
      return this.$router.push({
        name: 'UpdateGenericFileTypeConfiguration',
        params: {
          clientId: Number(this.$route.params.clientId),
          fileTypeId: this.$route.params.fileTypeId,
          providerId: this.$route.params.providerId,
        },
      });
    },
  },
};
</script>

<style scoped>
  .metadata-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
  }

  .metadata-row {
    display: flex;
    margin-bottom: 5px;
  }

  .metadata-label {
    font-weight: bold;
    margin-right: 5px;
    width: 200px;
  }

  .metadata-value {
    flex: 1;
  }

  .text-success {
    color: green;
  }

  .text-danger {
    color: red;
  }

  .table-container {
    max-height: 200px;
    overflow-y: auto;
  }
</style>
